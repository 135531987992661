<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <h1>Welkom</h1>
        <h3 class="text-info">{{ today }}</h3>
      </div>
    </div>
    <div class="row" v-if="kleedkamers.length">
      <div class="col-12 mb-3">
        <h2>Kleedkamers</h2>
        <b-card-group columns>
          <b-card v-for="kleedkamer in kleedkamers" :key="kleedkamer.id" :title="kleedkamer.resource.name">
            <h5 class="text-info" v-if="kleedkamer.comment">
              {{ kleedkamer.comment }}
            </h5>
            {{ kleedkamer.event.name }}
          </b-card>
        </b-card-group>
      </div>
    </div>
    <div class="row" v-if="events.length">
      <div class="col-12">
        <h2>Evenementen</h2>
        <b-card-group columns>
          <b-card v-for="event in events" :key="event.id" :title="event.name">
            {{ event.locations[0].name }}
            <b-badge variant="info" size="sm">{{ event.status.name }}</b-badge>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {datehelper} from "@/helpers/datehelper";

export default {
  data() {
    return {
      events: [],
      kleedkamers: [],
      locations: []
    }
  },
  computed: {
    today() {
      const dt = new Date().toDateString()
      return datehelper(dt).getWeekday() +" "+ datehelper(dt).formatDate()
    }
  },
  methods: {
    ...mapActions(['apiGet', 'hideMenu']),
    updateData() {
      this.events = []
      this.apiGet('events/date:%23today').then((result) => {
        result.data.data.forEach((event) => {
          if(event.status.name === 'Bevestigd' || event.status.name === 'Bezet') {
            if(this.locations.length > 0) {
              if(event.locations.length > 0 && this.locations.includes(event.locations[0].name)) {
                this.events.push(event)
              }
            } else {
              this.events.push(event)
            }
          }
        })
      })
      this.kleedkamers = []
      this.apiGet('resourcebookings/date:%23today role:kleedkamer').then((result) => {
        this.kleedkamers = result.data.data
      })
    }
  },
  mounted() {
    if(this.$route.params.filter != null) {
      this.locations = this.$route.params.filter.split(',')
    }
    this.updateData()
    setInterval(this.updateData, 300000)
    this.hideMenu()
  }
}
</script>